import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { Manager } from 'types/responseTypes';

export type ManagerProps = {
  manager: Manager;
  setManager: Function;
  update: Boolean;
  setUpdate: Function;
  getCredential: Function;
};

const initialState: ManagerProps = {
  setManager: () => {},
  getCredential: () => {},
  update: false,
  setUpdate: () => {},
  manager: {
    name: '',
    age: 0,
    especiality: '',
    tokenId: '',
    hasCredential: false,
    urlImageCharacter: '',
    urlImageManager: '',
    managerAvatarId: 0
  }
};

const ManagerContext = createContext(initialState);

type ManagerProviderProps = {
  children: ReactNode;
};

function ManagerProvider({ children }: ManagerProviderProps) {
  const { user } = useMoralis();
  const [update, setUpdate] = useState(initialState.update);
  const [manager, setManager] = useState(initialState.manager);

  // no se usaban error y loading
  const { fetch: getCredential, data: getData } = useMoralisCloudFunction('getManager', {
    userAddress: user?.attributes.ethAddress
  });

  useEffect(() => {
    if (getData) {
      const res = getData as Manager;
      setManager(res);
    }
  }, [getData]);

  useEffect(() => {
    if (update) getCredential();
    setUpdate(false);
  }, [update]);

  return (
    <ManagerContext.Provider
      value={{
        setManager,
        manager,
        update,
        setUpdate,
        getCredential
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
}

export { ManagerProvider, ManagerContext };
