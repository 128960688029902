// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/mui/CollapseDrawerContext';
import { MaintenanceProvider } from './contexts/MaintenanceContext';
import { ManagerProvider } from 'contexts/ManagerContext';
import { TokenTVCProvider } from 'contexts/TokenTVCContext';
import { CharacterNFTProvider } from 'contexts/CharacterNFTContext';
import { ToursProvider } from 'contexts/ToursContext.tsx';
import { ErrorModalLauncherProvider } from 'contexts/ErrorModalLauncherContext';
import { ModalsProvider } from 'contexts/ModalsContext';

//Moralis Provider
import { MoralisProvider } from 'react-moralis';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { TicketsProvider } from 'contexts/TicketsContext';

const moralisConfig = {
  appId: process.env.REACT_APP_APP_ID || '',
  serverUrl: process.env.REACT_APP_SERVER_URL || ''
};

ReactDOM.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <MoralisProvider appId={moralisConfig.appId} serverUrl={moralisConfig.serverUrl}>
              <ModalsProvider>
                <MaintenanceProvider>
                  <TokenTVCProvider>
                    <ManagerProvider>
                      <CharacterNFTProvider>
                        <TicketsProvider>
                          <ToursProvider>
                            <ErrorModalLauncherProvider>
                              <App />
                            </ErrorModalLauncherProvider>
                          </ToursProvider>
                        </TicketsProvider>
                      </CharacterNFTProvider>
                    </ManagerProvider>
                  </TokenTVCProvider>
                </MaintenanceProvider>
              </ModalsProvider>
            </MoralisProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
