import { useRef, useState, useEffect, useContext } from 'react';
import { shortAddress } from 'utils/addressShort';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  IconButton,
  Avatar,
  Stack,
  Typography,
  Hidden,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';

// components
import MenuPopover from '../../components/MenuPopover';
import TokenDetail from './TokenDetail';
import { useNavigate } from 'react-router';

// hooks
import useLogin from '../../hooks/useLogin';
import useUser from '../../hooks/useUser';
import useWordings from 'locales/wordings/useWordings';

// context
import { TokenTVCContext } from 'contexts/TokenTVCContext';
// config
import { Icon } from '@iconify/react';
// ----------------------------------------------------------------------

export default function AccountBar() {
  const { TVCBalance, ETHBalance: internalETHBalance } = useContext(TokenTVCContext);
  const [isMobile, setIsMobile] = useState(false);
  const { sessionWordings, activitiesCenterWordings } = useWordings();
  const { user, endSession, initSession, account } = useLogin();
  const { balance, getBalance, tvcBalance, getTokenBalances, ethBalance } = useUser();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      initSession();
    } else {
      getBalance(user?.attributes.ethAddress);
      getTokenBalances(user?.attributes.ethAddress);
    }
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!theme.breakpoints.down('sm')) {
      setIsMobile(true);
    }
  }, [theme]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          maxWidth: 500
        }}
      >
        {user && (
          <Hidden smDown>
            <TokenDetail
              image="/static/tokens/ethIcon.svg"
              value={internalETHBalance.toString()}
              isScientificNotation={true}
            />
            <TokenDetail image="/static/tokens/tvc.svg" value={TVCBalance.toString()} />
          </Hidden>
        )}
      </Stack>
      <Stack
        justifyContent="center"
        sx={{
          p: 0,
          borderRadius: 2,
          position: 'relative',
          bgcolor: '#1890FF',
          overflow: 'hidden'
        }}
      >
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: '0px 5px 0px 0px',
            width: '100%',
            height: 35,
            bgcolor: 'grey',
            cursor: 'pointer',
            ...(open && {
              '&:before': {
                zIndex: -100,
                content: "''",
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[600], 0.42)
              }
            })
          }}
        >
          <Avatar src="/static/avatars/girl.svg" alt="photoURL" />
          <Typography color={'white'}>{shortAddress(account)}</Typography>

          <Icon
            style={{ color: 'white', marginLeft: '5px' }}
            icon={open ? 'akar-icons:chevron-up' : 'akar-icons:chevron-down'}
            fontSize={18}
          />
        </IconButton>
      </Stack>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Hidden smUp>
          <Box sx={{ my: 1.5, px: 1 }}>
            <Typography variant="body1">{sessionWordings.inTheGame}</Typography>
            <Box sx={{ my: 2 }}>
              <TokenDetail
                image="/static/tokens/tvc.svg"
                value={TVCBalance.toString()}
                style={{ border: 'none', mb: 1, mt: 1 }}
              />
            </Box>
            <Box>
              <TokenDetail
                image="/static/tokens/ethIcon.svg"
                value={internalETHBalance.toString()}
                style={{ border: 'none', mb: 1, mt: 1 }}
                isScientificNotation={true}
              />
            </Box>
          </Box>
          {/* divider */}
          <Box
            sx={{
              background: 'rgba(145, 158, 171, 0.48)',
              height: '2px',
              width: '100%'
            }}
          />
        </Hidden>

        <Box>
          <Grid container sx={{ marginBottom: '15px' }}>
            {isMobile && (
              <>
                <Grid item sx={{ p: 1 }}>
                  <Typography variant="body1">In Game</Typography>

                  <TokenDetail
                    image="/static/tokens/tvc.svg"
                    value={TVCBalance ? TVCBalance.toString() : '0'}
                    style={{ border: 'none', mb: 1, mt: 1 }}
                  />
                  <TokenDetail
                    type="eth"
                    image="/static/tokens/ethIcon.svg"
                    value={internalETHBalance?.toString()}
                    isScientificNotation={true}
                    style={{ border: 'none', mb: 1 }}
                  />
                </Grid>
                <Box
                  sx={{
                    background: 'rgba(145, 158, 171, 0.48)',
                    height: '2px',
                    width: '100%'
                  }}
                />
              </>
            )}
            <Grid item sx={{ p: 1 }}>
              <Typography variant="body1">{sessionWordings.myWallet}</Typography>

              <TokenDetail
                image="/static/tokens/tvc.svg"
                value={tvcBalance ? tvcBalance.toString() : '0'}
                style={{ border: 'none', mb: 1, mt: 2 }}
              />

              <TokenDetail
                image="/static/tokens/bnb.svg"
                value={balance}
                style={{ border: 'none', mb: 1 }}
              />
              <TokenDetail
                type="eth"
                image="/static/tokens/ethIcon.svg"
                value={ethBalance?.toString()}
                style={{ border: 'none', mb: 1 }}
                isScientificNotation={true}
              />
            </Grid>

            {/* divider */}
            <Box
              sx={{
                background: 'rgba(145, 158, 171, 0.48)',
                height: '2px',
                width: '100%'
              }}
            />
            <Grid sx={{ p: 2 }}>
              <Grid
                item
                sx={{
                  '&:hover': {
                    color: '#188FFD'
                  },

                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  cursor: 'pointer',
                  height: 40
                }}
              >
                <Icon icon="eva:bell-fill" style={{ marginRight: '10px' }} fontSize={24} />
                <Typography variant="body1" onClick={() => navigate('/activities')}>
                  {activitiesCenterWordings.title}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  '&:hover': {
                    color: '#188FFD'
                  },
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  cursor: 'pointer',
                  height: 40
                }}
              >
                <Icon
                  icon="ic:outline-label-important"
                  style={{ marginRight: '10px' }}
                  fontSize={24}
                />
                <Typography variant="body1" onClick={() => navigate('/bridge')}>
                  Bridge
                </Typography>
              </Grid>
            </Grid>
            <Button
              sx={{ ml: 1, mr: 1 }}
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => endSession()}
            >
              {sessionWordings.disconnect}
            </Button>
          </Grid>
        </Box>
      </MenuPopover>
    </>
  );
}
