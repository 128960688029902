import { Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import blockChainAnimation from '../../assets/lotties/blockchainload.json';
import wordings from '../../locales/wordings/useWordings';

const TransactionInProcessBody = ({ title }: { title?: string }) => {
  const { processingTranslationWording } = wordings();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Lottie style={{ width: '95px', height: '95px' }} animationData={blockChainAnimation} />
      <Typography variant="body1" textAlign="center" gutterBottom sx={{ pt: 6 }}>
        {title || processingTranslationWording.title}
      </Typography>
    </Container>
  );
};

export default TransactionInProcessBody;
