import { useContext } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Music from 'components/Music';
import { BaseOptionChartStyle } from 'components/dashboard/BaseOptionChart';
// context
import InstallMetamaskModal from 'components/modals/MetamaskInstallModal';
import BaseModal from 'components/modals/BaseModal';
import { ErrorModalLauncherContext } from 'contexts/ErrorModalLauncherContext';
import { ModalsContext } from 'contexts/ModalsContext';

// ----------------------------------------------------------------------

export default function App() {
  const { ModalContext } = useContext(ModalsContext); // to any modal
  const { modalBodySelected, open, handleClose, isClosable } =
    useContext(ErrorModalLauncherContext);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <GlobalStyles />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <Music />
            <InstallMetamaskModal />
            <ModalContext />
            <BaseModal open={open} handleClose={() => handleClose()} closable={isClosable}>
              {modalBodySelected}
            </BaseModal>
            <Router />
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
