import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import Characters from './Characters.json';
import MysteryBox from './MysteryBox.json';
import Promocode from './Promocode.json';

const charactersContractAddress = process.env.REACT_APP_ADDRESS_CHARACTERS!;
const charactersInterface = new utils.Interface(Characters.abi);
const charactersContract = new Contract(charactersContractAddress, charactersInterface);

const mysteryBoxContractAddress = process.env.REACT_APP_ADDRESS_MYSTERYBOX!;
const mysteryBoxInterface = new utils.Interface(MysteryBox.abi);
const mysteryBoxContract = new Contract(mysteryBoxContractAddress, mysteryBoxInterface);

const promocodeContractAddress = process.env.REACT_APP_ADDRESS_PROMOCODES!;
const promocodeInterface = new utils.Interface(Promocode.abi);
const promocodeContract = new Contract(promocodeContractAddress, promocodeInterface);

const contractConfig = {
  characters: {
    contract: charactersContract,
    abi: Characters.abi,
    interface: charactersInterface,
    address: charactersContractAddress,
    functions: {
      buyMysteryBoxNative: 'buyMysteryBoxNative',
      buyMysteryBoxTVC: 'buyMysteryBoxTVC'
    }
  },
  mysteryBox: {
    contract: mysteryBoxContract,
    abi: MysteryBox.abi,
    interface: mysteryBoxInterface,
    address: mysteryBoxContractAddress,
    functions: {
      mysteryBoxDetails: 'mysteryBoxDetails'
    }
  },
  promocode: {
    contract: promocodeContract,
    abi: Promocode.abi,
    interface: promocodeInterface,
    address: promocodeContractAddress,
    functions: {
      validateCode: 'validateCode'
    }
  }
};

export default contractConfig;
