import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// context
import { MaintenanceContext } from '../contexts/MaintenanceContext';
import CreateManager from 'pages/CreateManager';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { maintenanceGral } = useContext(MaintenanceContext);

  return useRoutes([
    {
      path: '/',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/shop" replace />
        },
        {
          path: 'tickets',
          element: maintenanceGral ? <MaintenancePage /> : <Tickets />
        },
        {
          path: '/tickets/:id',
          element: maintenanceGral ? <MaintenancePage /> : <TicketBuy />
        },
        {
          path: 'shop',
          element: maintenanceGral ? <MaintenancePage /> : <Shop />
        },
        {
          path: 'my-mystery-box',
          element: maintenanceGral ? <MaintenancePage /> : <MyMysteryBox />
        },
        {
          path: '/my-collection',
          element: maintenanceGral ? <MaintenancePage /> : <MyCollection />,
          children: [
            {
              path: '/my-collection',
              element: maintenanceGral ? <MaintenancePage /> : <MyCollection />
            },
            {
              path: '/my-collection/inventory',
              element: maintenanceGral ? <MaintenancePage /> : <Inventory />
            },
            {
              path: '/my-collection/inventory/:characterId',
              element: maintenanceGral ? <MaintenancePage /> : <Inventory />
            }
          ]
        }
      ]
    },

    {
      path: '/my-manager',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/my-manager',
          element: maintenanceGral ? <MaintenancePage /> : <MyManager />
        },
        {
          path: '/my-manager/create-manager',
          element: maintenanceGral ? <MaintenancePage /> : <CreateManager />
        },
        {
          path: '/my-manager/share/:address',
          element: maintenanceGral ? <MaintenancePage /> : <SharedManager />
        }
      ]
    },
    {
      path: '/play-game',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/play-game',
          element: maintenanceGral ? <MaintenancePage /> : <PlayGame />
        },
        {
          path: '/play-game/overtime',
          element: maintenanceGral ? <MaintenancePage /> : <OvertimeGame />
        },
        {
          path: '/play-game/duels',
          element: maintenanceGral ? <MaintenancePage /> : <DuelsGame />
        },
        {
          path: '/play-game/tours',
          element: maintenanceGral ? <MaintenancePage /> : <ToursGame />
        },
        {
          path: '/play-game/tours/create-local-tour',
          element: maintenanceGral ? <MaintenancePage /> : <CreateTour />
        },
        {
          path: '/play-game/tours/create-international-tour',
          element: maintenanceGral ? <MaintenancePage /> : <CreateTour />
        },
        {
          path: '/play-game/tours/visit/:id',
          element: maintenanceGral ? <MaintenancePage /> : <VisitTour />
        },
        {
          path: '/play-game/tours/resume/:id',
          element: maintenanceGral ? <MaintenancePage /> : <ResumeTour />
        }
      ]
    },
    {
      path: '/bridge',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/bridge',
          element: maintenanceGral ? <MaintenancePage /> : <Bridge />
        }
      ]
    },
    {
      path: '/activities',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/activities',
          element: maintenanceGral ? <MaintenancePage /> : <ActivitiesCenter />
        }
      ]
    },
    {
      path: '/staking',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/staking',
          element: maintenanceGral ? <MaintenancePage /> : <Stacking />
        }
      ]
    },
    {
      path: '/ranking',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/ranking',
          element: maintenanceGral ? <MaintenancePage /> : <Ranking />
        }
      ]
    },
    {
      path: '/dashboard',
      element: maintenanceGral ? <LogoOnlyLayout /> : <DashboardLayout />,
      children: [
        {
          path: '/dashboard',
          element: maintenanceGral ? <MaintenancePage /> : <Dashboard />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/shop" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '/tokens/nft/*', element: '' },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
const Tickets = Loadable(lazy(() => import('../pages/tickets/Tickets')));
const TicketBuy = Loadable(lazy(() => import('../pages/tickets/TicketBuy')));
const Shop = Loadable(lazy(() => import('../pages/Shop')));
const MyMysteryBox = Loadable(lazy(() => import('../pages/MyMysteryBox')));
const MyCollection = Loadable(lazy(() => import('../pages/MyCollection')));
const PlayGame = Loadable(lazy(() => import('../pages/PlayGame')));
const MyManager = Loadable(lazy(() => import('../pages/MyManager')));
const SharedManager = Loadable(lazy(() => import('../pages/SharedManager')));
const OvertimeGame = Loadable(lazy(() => import('../pages/games/OvertimeGame')));
const DuelsGame = Loadable(lazy(() => import('../pages/games/DuelsGame')));
const ToursGame = Loadable(lazy(() => import('../pages/games/ToursGame')));
const CreateTour = Loadable(lazy(() => import('../pages/games/tours/CreateTourStepper')));
const VisitTour = Loadable(lazy(() => import('../pages/games/tours/VisitTour')));
const ResumeTour = Loadable(lazy(() => import('../pages/games/tours/ResumeTour')));
const NotFound = Loadable(lazy(() => import('../pages/statusPages/Page404')));
const MaintenancePage = Loadable(lazy(() => import('../pages/statusPages/MaintenancePage')));
const Bridge = Loadable(lazy(() => import('../pages/Bridge')));
const Stacking = Loadable(lazy(() => import('../pages/Stacking')));
const Ranking = Loadable(lazy(() => import('../pages/Ranking')));
const Inventory = Loadable(lazy(() => import('../components/Inventory')));
const ActivitiesCenter = Loadable(lazy(() => import('../pages/ActivitiesCenter')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
