import { Button, Container, Grid, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/lotties/error.json';

interface GenericErrorProps {
  title: string;
  descriptions: string[];
  buttonText?: string;
  url?: string;
  action?: Function;
}

const GenericErrorBody = (props: GenericErrorProps) => {
  const { title, descriptions, url, action, buttonText } = props;

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Lottie
        style={{ width: '95px', height: '95px' }}
        loop={false}
        animationData={errorAnimation}
      />
      <Typography variant="h6" textAlign="center" gutterBottom>
        {title}
      </Typography>
      {descriptions.map((desc) => (
        <Typography variant="body2" textAlign="center" key={desc}>
          {desc}
        </Typography>
      ))}
      <Grid sx={{ pt: 3 }}>
        {buttonText && url && (
          <Button
            variant="contained"
            color="primary"
            href={url}
            target={url.includes(window.origin) ? '_self' : '_blank'}
          >
            {buttonText}
          </Button>
        )}
        {buttonText && !url && action && (
          <Button variant="contained" color="primary" onClick={() => action()}>
            {buttonText}
          </Button>
        )}
      </Grid>
    </Container>
  );
};

export default GenericErrorBody;
