import { Container, Typography, Box } from '@mui/material';
import Lottie from 'lottie-react';
import maintenanceAnimation from '../../assets/lotties/maintenance.json';
import Page from '../../components/Page';
import useWordings from 'locales/wordings/useWordings';

const MaintenancePage = () => {
  const { maintenanceWordings } = useWordings();

  return (
    <Page
      title={maintenanceWordings.title}
      sx={{
        display: 'flex',
        minHeight: '100%',
        alignItems: 'center'
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5
        }}
      >
        <Box sx={{ maxWidth: 211 }}>
          <Lottie animationData={maintenanceAnimation} />
        </Box>
        <Typography variant="h6" textAlign="center" sx={{ py: 3 }}>
          {maintenanceWordings.descLine1}
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          {maintenanceWordings.descLine2}
        </Typography>
      </Container>
    </Page>
  );
};

export default MaintenancePage;
