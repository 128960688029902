import { ReactChild, ReactChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';

interface ModalProps {
  children: ReactChild | ReactChildren;
  open: boolean;
  handleClose: () => {};
  closable?: boolean;
}

const BaseModal = ({ children, handleClose, open, closable = true }: ModalProps) =>
  closable ? (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          onClick={handleClose}
          size="large"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Icon icon="ep:close" fontSize={30} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mb: 2 }}>{children}</DialogContent>
    </Dialog>
  ) : (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle sx={{ m: 0, p: 2 }}></DialogTitle>
      <DialogContent sx={{ mb: 2 }}>{children}</DialogContent>
    </Dialog>
  );

export default BaseModal;
