import { formatEther } from '@ethersproject/units';
import { useState, useEffect } from 'react';
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis';
import { DataBox } from 'types/nftTypes';
import contractConfig from '../contracts/config';

const { mysteryBox } = contractConfig;

export default function useMysteryBoxDetails(id: number) {
  const { isWeb3Enabled, isWeb3EnableLoading } = useMoralis();
  const {
    data,
    error,
    fetch: mysteryBoxDetails,
    isFetching,
    isLoading
  } = useWeb3ExecuteFunction(
    {
      abi: mysteryBox.abi,
      contractAddress: mysteryBox.address,
      functionName: mysteryBox.functions.mysteryBoxDetails,
      params: {
        _mysteryBoxId: [id]
      }
    },
    { autoFetch: false }
  );

  const getMysteryBox = async () => {
    if (isWeb3Enabled && !isWeb3EnableLoading) {
      await mysteryBoxDetails({
        onError: (error: Error) => {
          console.log('error', error);
        }
      });
    }
  };

  useEffect(() => {
    getMysteryBox();
  }, [isWeb3Enabled]);

  const [box, setBox] = useState<DataBox | undefined>();

  function dataToBox(dataBox: DataBox, id: number) {
    if (dataBox) {
      const box: DataBox = {
        id,
        name: dataBox.name,
        priceNative: Number(formatEther(dataBox.priceNative)),
        priceNativeDesc: Number(formatEther(dataBox.priceNativeDesc)),
        priceTVC: Number(formatEther(dataBox.priceTVC)),
        priceTVCDesc: Number(formatEther(dataBox.priceTVCDesc)),
        priceNativeBN: dataBox.priceNative,
        priceNativeDescBN: dataBox.priceNativeDesc,
        priceTVCBN: dataBox.priceTVC,
        priceTVCDescBN: dataBox.priceTVCDesc,
        arrayFreqs: dataBox.arrayFreqs.map((freq: any) => formatEther(freq)),
        stock: Number(dataBox.stock)
      };
      return box;
    }
  }

  useEffect(() => {
    if (data) {
      setBox(dataToBox(data as DataBox, id));
    }
  }, [data, id]);

  return {
    box,
    error,
    getMysteryBox,
    isFetching,
    isLoading
  };
}
