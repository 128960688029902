import { useEffect, useState } from 'react';
import { useChain, useMoralis } from 'react-moralis';

export default function useCheckChain() {
  const { chainId, chain } = useChain();
  const { isAuthenticated } = useMoralis();

  const chainName = chain?.name;
  const deployChainId = process.env.REACT_APP_CHAIN_ID;
  const [invalidChain, setInvalidChain] = useState(false);

  useEffect(() => {
    setInvalidChain(chainId !== deployChainId);
  }, [chainId, isAuthenticated]);

  return {
    invalidChain,
    chainName
  };
}
