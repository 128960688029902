import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralisCloudFunction } from 'react-moralis';

// ----------------------------------------------------------------------

export type MaintenanceProps = {
  maintenanceGral: boolean;
  maintenanceMyCollection: boolean;
  maintenanceMyMysteryBox: boolean;
  maintenancePlayGame: boolean;
  maintenanceOvertime: boolean;
  maintenanceDuels: boolean;
  maintenanceShows: boolean;
  maintenanceSchool: boolean;
  maintenanceCreateManager: boolean;
  maintenanceGetManager: boolean;
  maintenanceUpdateManager: boolean;
  maintenanceGetBalance: boolean;
  maintenanceTickets: boolean;
};

const initialState: MaintenanceProps = {
  maintenanceGral: false,
  maintenanceMyCollection: false,
  maintenanceMyMysteryBox: false,
  maintenancePlayGame: false,
  maintenanceOvertime: false,
  maintenanceDuels: false,
  maintenanceShows: false,
  maintenanceSchool: false,
  maintenanceCreateManager: false,
  maintenanceGetManager: false,
  maintenanceUpdateManager: false,
  maintenanceGetBalance: false,
  maintenanceTickets: false
};

const MaintenanceContext = createContext(initialState);

type MaintenanceProviderProps = {
  children: ReactNode;
};

function MaintenanceProvider({ children }: MaintenanceProviderProps) {
  const { data, error } = useMoralisCloudFunction('getStatus');
  const [maintenanceGral, setMaintenanceGral] = useState(false);
  const [maintenanceMyCollection, setMaintenanceMyCollection] = useState(false);
  const [maintenanceMyMysteryBox, setMaintenanceMyMysteryBox] = useState(false);
  const [maintenancePlayGame, setMaintenancePlayGame] = useState(false);
  const [maintenanceOvertime, setMaintenanceOvertime] = useState(false);
  const [maintenanceDuels, setMaintenanceDuels] = useState(false);
  const [maintenanceShows, setMaintenanceShows] = useState(false);
  const [maintenanceSchool, setMaintenanceSchool] = useState(false);
  const [maintenanceCreateManager, setMaintenanceCreateManager] = useState(false);
  const [maintenanceGetManager, setMaintenanceGetManager] = useState(false);
  const [maintenanceGetBalance, setMaintenanceGetBalance] = useState(false);
  const [maintenanceUpdateManager, setMaintenanceUpdateManager] = useState(false);
  const [maintenanceTickets, setMaintenanceTickets] = useState(false);

  useEffect(() => {
    if (data) {
      const res = data as MaintenanceProps;
      setMaintenanceGral(res.maintenanceGral);
      setMaintenanceMyCollection(res.maintenanceMyCollection);
      setMaintenanceMyMysteryBox(res.maintenanceMyMysteryBox);
      setMaintenancePlayGame(res.maintenancePlayGame);
      setMaintenanceOvertime(res.maintenanceOvertime);
      setMaintenanceDuels(res.maintenanceDuels);
      setMaintenanceShows(res.maintenanceShows);
      setMaintenanceSchool(res.maintenanceSchool);
      setMaintenanceCreateManager(res.maintenanceCreateManager);
      setMaintenanceGetManager(res.maintenanceGetManager);
      setMaintenanceGetBalance(res.maintenanceGetBalance);
      setMaintenanceUpdateManager(res.maintenanceUpdateManager);
      setMaintenanceTickets(res.maintenanceTickets);
    }
  }, [data]);

  useEffect(() => {
    if (error) console.log('errorMaintenanceContext', error);
  }, [error]);

  return (
    <MaintenanceContext.Provider
      value={{
        maintenanceGral,
        maintenanceMyCollection,
        maintenanceMyMysteryBox,
        maintenancePlayGame,
        maintenanceOvertime,
        maintenanceDuels,
        maintenanceShows,
        maintenanceSchool,
        maintenanceCreateManager,
        maintenanceGetManager,
        maintenanceGetBalance,
        maintenanceUpdateManager,
        maintenanceTickets
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
}

export { MaintenanceProvider, MaintenanceContext };
