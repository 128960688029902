import { useMoralis, useChain } from 'react-moralis';
import { useEffect } from 'react';
import useUser from 'hooks/useUser';
import Moralis from 'moralis';
import useWordings from 'locales/wordings/useWordings';

export default function useLogin() {
  const {
    authenticate,
    logout,
    account,
    user,
    isAuthenticated,
    isWeb3Enabled,
    isWeb3EnableLoading,
    enableWeb3
  } = useMoralis();
  const { switchNetwork, chainId } = useChain();
  const { listenToUpdate } = useUser();
  const { sessionWordings } = useWordings();
  const deployChainId = process.env.REACT_APP_CHAIN_ID!;

  const checkChain = async () => {
    if (chainId !== deployChainId) {
      await switchNetwork(deployChainId);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      checkChain();
    }
  }, [chainId, isAuthenticated, isWeb3Enabled]);

  const init = async (user: Moralis.User<Moralis.Attributes>) => {
    if (!isWeb3EnableLoading && !isWeb3Enabled) {
      await enableWeb3();
      await listenToUpdate(user.attributes.ethAddress);
    }
  };

  useEffect(() => {
    if (user && isAuthenticated) {
      init(user);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (user && account && user?.attributes.ethAddress !== account) {
      logout();
    }
  }, [account]);

  const chainIdDecimal = Number(process.env.REACT_APP_CHAIN_ID_DECIMAL!);
  const initSession = async () => {
    if (!isAuthenticated) {
      await authenticate({
        chainId: chainIdDecimal, //no funciona, gracias moralis
        signingMessage: sessionWordings.signMessage,
        onError: (error) => {
          console.log('error authenticate', error);
        }
      });
    }
  };

  const endSession = () => {
    if (user) {
      const userName = user.get('username');
      if (userName) {
        logout();
      }
    }
  };

  return {
    user,
    account,
    initSession,
    endSession,
    logout,
    isAuthenticated
  };
}
