// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  let url;
  const theme = useTheme();

  theme.palette.mode === 'light'
    ? (url = '/static/logo-light.svg')
    : (url = '/static/logo-dark.svg');

  return <Box component="img" src={url} sx={{ width: 196, height: 96, ...sx }} />;
}
