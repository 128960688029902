import { Button, Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/lotties/error.json';
import useWordings from '../../locales/wordings/useWordings';
import { avatars } from 'utils/avatars';
import { Grid } from '@mui/material';
import { useEffect } from 'react';

interface AvatarProps {
  avatar: AvatarObject;
  setAvatar: Function;
  handleClose: Function;
}

interface AvatarObject {
  src: string;
  id: number;
}
const AvatarsBody = ({ setAvatar, avatar, handleClose }: AvatarProps) => {
  const { purchaseErrorModalWordings } = useWordings();

  const handleSelect = (url: AvatarObject) => {
    setAvatar(url);
    handleClose();
  };

  return (
    <Container
      fixed
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography variant="h6" textAlign="center" gutterBottom>
        Select your Avatar
      </Typography>
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'center' }}
        columns={{ xs: 4, md: 12 }}
      >
        {avatars.map((svg) => {
          return (
            <Grid md={3} item key={svg.src} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                onClick={() => handleSelect(svg)}
                style={{ cursor: 'pointer' }}
                src={svg.src}
                alt={svg.src}
                key={svg.src}
              ></img>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default AvatarsBody;
