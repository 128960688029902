import { Container, Typography, Button, Avatar } from '@mui/material';
import useWordings from 'locales/wordings/useWordings';
import useLogin from '../../hooks/useLogin';

const ConnectWalletBody = ({ type = 'mystery' }) => {
  const { initSession } = useLogin();
  const { metamaskModalWordings, metamaskMyCollectionModalWordings } = useWordings();

  const mysteryDescription = [metamaskModalWordings.descLine1, metamaskModalWordings.descLine2];
  const nftDescription = [
    metamaskMyCollectionModalWordings.descLine1,
    metamaskMyCollectionModalWordings.descLine2
  ];
  const description = type === 'mystery' ? mysteryDescription : nftDescription;

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar
        src="/static/illustrations/metamask.svg"
        variant="square"
        sx={{
          width: 99,
          height: 86,
          mb: 3
        }}
      />
      <Typography variant="h6" textAlign="center" gutterBottom>
        {metamaskModalWordings.title}
      </Typography>
      {description.map((line, i) => (
        <Typography variant="body2" textAlign="center" key={i}>
          {line}
        </Typography>
      ))}
      <Button variant="contained" onClick={() => initSession()} sx={{ borderRadius: 50, mt: 3 }}>
        {metamaskModalWordings.buttonText}
      </Button>
    </Container>
  );
};

export default ConnectWalletBody;
