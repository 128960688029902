import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { DataToNewTour, NewTour, Tour } from 'types/toursTypes';

export const INITIAL_VOID_TOUR: NewTour = {
  character: {
    characterType: 0,
    condition: 0,
    currentEnergy: 0,
    ego: 0,
    experience: 0,
    followers: 0,
    healthStatus: 0,
    imageUrl: '',
    moral: 0,
    rarityType: 0,
    stateOfMind: 0,
    statusList: {
      description: {
        es: '',
        en: ''
      },
      statusList: [
        {
          color: '',
          description: {
            es: '',
            en: ''
          },
          name: {
            es: '',
            en: ''
          },
          value: 0
        }
      ]
    },
    talent: 0,
    tokenId: '',
    totalEnergy: 0,
    vitality: 0,
    level: 0,
    percentageLevel: 0
  },
  stages: [],
  soundSystem: {
    cost: 0,
    description: {
      es: '',
      en: ''
    },
    image: '',
    name: {
      es: '',
      en: ''
    },
    power: 0,
    quality: {
      es: '',
      en: ''
    },
    soundSystemId: 0,
    type: {
      es: '',
      en: ''
    }
  },
  accommodation: {
    accommodationId: 0,
    cost: 0,
    description: {
      es: '',
      en: ''
    },
    image: '',
    name: {
      es: '',
      en: ''
    },
    rating: 0,
    type: {
      es: '',
      en: ''
    }
  },
  mobility: {
    capacity: 0,
    cost: 0,
    description: {
      es: '',
      en: ''
    },
    image: '',
    mobilityId: 0,
    name: {
      es: '',
      en: ''
    },
    rating: 0,
    type: {
      es: '',
      en: ''
    }
  },
  publicity: [],
  totalCost: 0
};

const INITIAL_DATA_TO_NEW_TOUR: DataToNewTour = {
  characters: [],
  stages: [],
  soundSystems: [],
  accommodations: [],
  mobilities: [],
  publicities: [],
  tourTotalHours: 0
};

// ----------------------------------------------------------------------

export type ToursProps = {
  newTour: NewTour; // nuevo tour del usuario
  setNewTour: React.Dispatch<React.SetStateAction<NewTour>>;
  tours: Tour[]; // tours del usuario
  refreshTours: Function;
  dataToNewTour: DataToNewTour; // socotroco de data para crear un nuevo tour
  setDataToNewTour: React.Dispatch<React.SetStateAction<DataToNewTour>>;
  getDataToNewTour: Function;
  generateTour: Function;
};

const initialState: ToursProps = {
  newTour: INITIAL_VOID_TOUR,
  setNewTour: () => {},
  tours: [],
  refreshTours: Function,
  dataToNewTour: INITIAL_DATA_TO_NEW_TOUR,
  setDataToNewTour: () => {},
  getDataToNewTour: Function,
  generateTour: () => {}
};

const ToursContext = createContext(initialState);

type ToursProviderProps = {
  children: ReactNode;
};

function ToursProvider({ children }: ToursProviderProps) {
  const { user } = useMoralis();
  const [newTour, setNewTour] = useState<NewTour>(INITIAL_VOID_TOUR);
  const [tours, setTours] = useState<Tour[]>([]);
  const [dataToNewTour, setDataToNewTour] = useState<DataToNewTour>(INITIAL_DATA_TO_NEW_TOUR);

  const {
    data,
    error,
    fetch: fetchTours
  } = useMoralisCloudFunction('getTours', undefined, { autoFetch: false });
  const {
    data: dataToCreate,
    error: errorToCreate,
    fetch: fetchDataToNewTour
  } = useMoralisCloudFunction('getDataToCreateTour', undefined, { autoFetch: false });

  useEffect(() => {
    refreshTours();
    getDataToNewTour();
  }, [user]);

  useEffect(() => {
    if (data) {
      setTours(data as Tour[]);
    }
  }, [data]);

  useEffect(() => {
    if (dataToCreate) {
      setDataToNewTour(dataToCreate as DataToNewTour);
      setDataToNewTour((dataToNewTour) => ({
        ...dataToNewTour,
        publicities: (dataToCreate as any).publicites
      })); // esto xq mandan publicites en vez de publicities, vuela cuando lo acomoden
    }
  }, [dataToCreate]);

  useEffect(() => {
    if (errorToCreate) {
      console.log('errorToCreate :>> ', errorToCreate);
    }
  }, [errorToCreate]);

  useEffect(() => {
    if (error) {
      console.log('getTours error :>> ', error);
    }
  }, [error]);

  const refreshTours = async () => {
    if (user && !tours.length) {
      fetchTours();
    }
  };

  const getDataToNewTour = async () => {
    if (user && !dataToNewTour.stages.length) {
      fetchDataToNewTour();
    }
  };

  const generateTour = () => {
    let tokenId = newTour.character.tokenId;
    let stages = newTour.stages.map((el) => {
      return { stageId: el.stageId };
    });
    let soundSystemId = newTour.soundSystem.soundSystemId;
    let accommodationId = newTour.accommodation.accommodationId;
    let mobilityId = newTour.mobility.mobilityId;
    let publicities = newTour.publicity.map((el) => {
      return { publicityId: el.publicityId, quantity: el.quantity };
    });

    return { tokenId, stages, soundSystemId, accommodationId, mobilityId, publicities };
  };

  return (
    <ToursContext.Provider
      value={{
        newTour,
        setNewTour,
        tours,
        refreshTours,
        dataToNewTour,
        setDataToNewTour,
        getDataToNewTour,
        generateTour
      }}
    >
      {children}
    </ToursContext.Provider>
  );
}

export { ToursProvider, ToursContext };
