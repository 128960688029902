import { MIconButton } from 'components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';

export type SnackbarStatus = 'error' | 'success' | 'default' | 'info' | 'warning';

export default function useLaunchSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const launchSnackbar = (message: string, status?: SnackbarStatus) => {
    enqueueSnackbar(message, {
      variant: status || 'default',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  return {
    launchSnackbar
  };
}
