import { useState } from 'react';
import BaseModal from '../components/modals/BaseModal';

export default function useModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    return true;
  };

  return {
    open,
    handleOpen,
    handleClose,
    BaseModal
  };
}
