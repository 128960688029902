import BaseModal from 'components/modals/BaseModal';
import { ReactNode, createContext, useState, SetStateAction } from 'react';
// ----------------------------------------------------------------------

export type ModalsProps = {
  open: boolean;
  isClosable: boolean;
  handleOpen: VoidFunction;
  handleClose: VoidFunction;
  handleIsClosable: (closable: boolean) => void;
  ModalContext: () => JSX.Element;
  setModalBody: (newModalBody: JSX.Element) => void;
  previousModalBody: JSX.Element;
};

const initialState: ModalsProps = {
  open: false,
  isClosable: true,
  handleOpen: () => {},
  handleClose: () => {},
  handleIsClosable: (closable: boolean) => {},
  ModalContext: () => <></>,
  setModalBody: (value: SetStateAction<JSX.Element>) => {},
  previousModalBody: <></>
};

const ModalsContext = createContext(initialState);

type ModalsProviderProps = {
  children: ReactNode;
};

function ModalsProvider({ children }: ModalsProviderProps) {
  const [open, setOpen] = useState(false);
  const [isClosable, setIsClosable] = useState(true);
  const [modalBody, setModalBody] = useState(<></>);
  const [previousModalBody, setPreviousModalBody] = useState(<></>);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    return true;
  };

  const handleIsClosable = (closable: boolean) => {
    setIsClosable(closable);
  };

  const handleModalBodyChange = (newModalBody: JSX.Element) => {
    setPreviousModalBody(modalBody);
    setModalBody(newModalBody);
  };

  const ModalContext = () => (
    <BaseModal handleClose={() => handleClose()} open={open} closable={isClosable}>
      {modalBody}
    </BaseModal>
  );

  return (
    <ModalsContext.Provider
      value={{
        open,
        isClosable,
        ModalContext,
        handleClose,
        handleOpen,
        handleIsClosable,
        setModalBody: handleModalBodyChange,
        previousModalBody
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}

export { ModalsProvider, ModalsContext };
