import { useEffect } from 'react';
import { Container, Typography, Button, Avatar } from '@mui/material';
import BaseModal from './BaseModal';
// hooks
import useWordings from 'locales/wordings/useWordings';
import useModal from 'hooks/useModal';
import { useMoralis } from 'react-moralis';
// ----------------------------------------------------------------------

const InstallMetamaskModal = () => {
  const { metamaskInstallModalWordings } = useWordings();
  const { open, handleClose, handleOpen } = useModal();
  const { web3, provider, isWeb3Enabled } = useMoralis();

  useEffect(() => {
    if (!(window as any).ethereum) {
      handleOpen();
    }
  }, []);

  return (
    <BaseModal open={open} handleClose={async () => handleClose()}>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          src="/static/illustrations/metamask.svg"
          variant="square"
          sx={{
            width: 50,
            height: 50,
            mb: 3
          }}
        />
        <Typography variant="h6" textAlign="center" gutterBottom>
          {metamaskInstallModalWordings.title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {metamaskInstallModalWordings.descLine1}
        </Typography>
        <Typography variant="body2" textAlign="center" paragraph>
          {metamaskInstallModalWordings.descLine2}
        </Typography>
        <Button
          variant="contained"
          href="https://metamask.io/download"
          target="_blank"
          sx={{ borderRadius: 50 }}
        >
          {metamaskInstallModalWordings.buttonText}
        </Button>
      </Container>
    </BaseModal>
  );
};

export default InstallMetamaskModal;
