import { ReactNode, createContext, useState, useEffect, useCallback, useContext } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { Banner, ShopTicketResponse, Ticket } from 'types/ticketsTypes';

// ----------------------------------------------------------------------

export type TicketsProps = {
  tickets: Array<Ticket>;
  setTickets: Function;
  banners: Array<Banner>;
  emailVerified: boolean;
  setEmailVerified: Function;
  email: string;
  setEmail: Function;
  fetchTickets: Function;
  ticketsError: Error | null;
  isLoading: boolean;
  getTicket: Function;
};

const initialState: TicketsProps = {
  tickets: [],
  setTickets: Function,
  banners: [],
  fetchTickets: Function,
  emailVerified: false,
  setEmailVerified: Function,
  email: '',
  setEmail: Function,
  ticketsError: null,
  isLoading: false,
  getTicket: (ticketId: string) => {}
};

const TicketsContext = createContext(initialState);

type TicketsProviderProps = {
  children: ReactNode;
};

export function TicketsProvider({ children }: TicketsProviderProps) {
  const { user } = useMoralis();
  const {
    data,
    fetch,
    error: ticketsError,
    isLoading,
    isFetching
  } = useMoralisCloudFunction('getShopTickets', undefined, { autoFetch: false });
  const [tickets, setTickets] = useState<Array<Ticket>>([]);
  const [banners, setBanners] = useState<Array<Banner>>([]);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [email, setEmail] = useState('');

  const fetchTickets = useCallback(async () => {
    if (user) {
      await fetch();
    }
  }, [fetch, user]);

  const getTicket = (ticketId: string) => {
    return tickets.find((ticket) => ticket.idShopTicket === ticketId);
  };

  useEffect(() => {
    tickets.length === 0 && fetchTickets();
  }, [user, fetchTickets, tickets]);

  useEffect(() => {
    if (data) {
      console.log('data', data);
      const res = data as ShopTicketResponse;
      setTickets(res.shows);
      setBanners(res.banners);
      setEmail(res.email || '');
      setEmailVerified(res.emailVerified);
    }
  }, [data]);

  useEffect(() => {
    if (ticketsError) console.log('error Tickets Context', ticketsError);
  }, [ticketsError]);

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        setTickets,
        banners,
        emailVerified,
        setEmailVerified,
        email,
        setEmail,
        fetchTickets,
        ticketsError,
        isLoading: isLoading || isFetching,
        getTicket
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
}

const useTickets = () => {
  const context = useContext(TicketsContext);
  if (!context) throw new Error('Error in tickets context');
  return context;
};

export default useTickets;
