import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { CharacterNFT } from 'types/nftTypes';

// ----------------------------------------------------------------------

export type CharacterNFTProps = {
  collection: Array<CharacterNFT>;
  setCollection: Function;
  fetchMyCollection: Function;
  collectionError: Error | null;
};

const initialState: CharacterNFTProps = {
  collection: [],
  setCollection: Function,
  fetchMyCollection: Function,
  collectionError: null
};

const CharacterNFTContext = createContext(initialState);

type CharacterNFTProviderProps = {
  children: ReactNode;
};

function CharacterNFTProvider({ children }: CharacterNFTProviderProps) {
  const { user } = useMoralis();
  const {
    data,
    fetch,
    error: collectionError
  } = useMoralisCloudFunction('getMyCollection', undefined, { autoFetch: false });
  const [collection, setCollection] = useState<Array<CharacterNFT>>([]);

  const fetchMyCollection = async () => {
    if (user) {
      await fetch();
    }
  };

  useEffect(() => {
    collection.length === 0 && fetchMyCollection();
  }, [user]);

  useEffect(() => {
    if (data) {
      const res = data as Array<CharacterNFT>;
      setCollection(res);
    }
  }, [data]);

  useEffect(() => {
    if (collectionError) console.log('error CharacterNFT Context', collectionError);
  }, [collectionError]);

  return (
    <CharacterNFTContext.Provider
      value={{
        collection,
        setCollection,
        fetchMyCollection,
        collectionError
      }}
    >
      {children}
    </CharacterNFTContext.Provider>
  );
}

export { CharacterNFTProvider, CharacterNFTContext };
