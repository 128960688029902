import { useState, useEffect } from 'react';
import { Box, Stack, Grid, IconButton, Slider, FormControlLabel, Checkbox } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import YouTube, { Options } from 'react-youtube';
import type { YouTubePlayer } from 'youtube-player/dist/types';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import { getYoutubeUserData, saveYoutubeUserData, YoutubeData } from './youtubeStorage';
// ----------------------------------------------------------------------
const YoutubeStyle = styled(YouTube)(() => ({
  borderRadius: '20px',
  height: 400
}));

export default function Youtube() {
  const [youtubeFunctions, setYoutubeFunctions] = useState<YouTubePlayer>();
  const [youtubeUserData, setYoutubeUserData] = useState<YoutubeData>(
    getYoutubeUserData() || {
      playlist: 'PLx_r3rqb7_35T_t7J2ecdu0yJU70Dhb4s',
      currentSong: 0,
      autoplay: true,
      volume: 10
    }
  );

  const theme = useTheme();
  const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
  const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';
  const [paused, setPaused] = useState(true);

  // https://developers.google.com/youtube/player_parameters
  const playerOptions: Options = {
    width: '280',
    height: '540',
    playerVars: {
      enablejsapi: 1,
      mute: 0,
      autoplay: youtubeUserData.autoplay ? 1 : 0,
      origin: window.origin,
      list: youtubeUserData.playlist,
      listType: 'playlist',
      loop: 1, // repite la lista
      rel: 0, // recomendaciones de videos apagada
      modestbranding: 1, // quita logo de youtube de la barra
      fs: 1, // quita fullscreen
      controls: 2,
      iv_load_policy: 3 // quita anotaciones del video
    }
  };

  useEffect(() => {
    saveYoutubeUserData(youtubeUserData);
  }, [youtubeUserData]);

  const handleReady = async (e: { target: YouTubePlayer }) => {
    await e.target.playVideoAt(youtubeUserData.currentSong);
    if (youtubeUserData.autoplay) {
      await e.target.playVideo();
    } else {
      await e.target.stopVideo();
    }
    await e.target.setVolume(youtubeUserData.volume);
    await e.target.unMute();
    setYoutubeFunctions(e.target);
  };

  const handleVolume = (event: Event, volume: number | number[]) => {
    youtubeFunctions?.unMute();
    youtubeFunctions?.setVolume(volume as number);
    setYoutubeUserData({
      ...youtubeUserData,
      volume: volume as number
    });
  };

  const handleAutoplay = () => {
    setYoutubeUserData((previousData) => ({
      ...previousData,
      autoplay: !previousData.autoplay
    }));
  };

  const handleReproduction = () => {
    if (paused) {
      youtubeFunctions?.playVideo();
    } else {
      youtubeFunctions?.pauseVideo();
    }
    setPaused((paused) => !paused);
  };

  const handleSong = (action: 'previous' | 'next') => {
    if (action === 'next') {
      youtubeFunctions?.nextVideo();
    } else {
      youtubeFunctions?.previousVideo();
    }
  };

  return (
    <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
      <Stack spacing={1.5}>
        <Grid container justifyContent="center">
          <YoutubeStyle
            opts={playerOptions}
            onReady={handleReady}
            onPlay={(_e) => setPaused(false)}
            onPause={(_e) => setPaused(true)}
            onStateChange={async (e) => {
              const videoId = await e.target.getPlaylistIndex();
              if (videoId) {
                setYoutubeUserData({
                  ...youtubeUserData,
                  currentSong: videoId
                });
              }
            }}
          />
        </Grid>
      </Stack>
      <Stack spacing={1.5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: -4
          }}
        >
          <Grid container>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleAutoplay}
                  checked={youtubeUserData.autoplay}
                />
              }
              label="Autoplay"
              labelPlacement="end"
            />
          </Grid>
          <IconButton onClick={() => handleSong('previous')}>
            <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
          <IconButton onClick={handleReproduction}>
            {paused ? (
              <PlayArrowRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
            ) : (
              <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
            )}
          </IconButton>
          <IconButton onClick={() => handleSong('next')}>
            <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
        </Box>
        <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
          <VolumeDownRounded htmlColor={lightIconColor} />
          <Slider
            value={youtubeUserData.volume}
            onChange={handleVolume}
            sx={{
              color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
              '& .MuiSlider-track': {
                border: 'none'
              },
              '& .MuiSlider-thumb': {
                width: 24,
                height: 24,
                backgroundColor: '#fff',
                '&:before': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none'
                }
              }
            }}
          />
          <VolumeUpRounded htmlColor={lightIconColor} />
        </Stack>
      </Stack>
    </Stack>
  );
}
