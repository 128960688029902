import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';

// ----------------------------------------------------------------------

export type TokenTVCProps = {
  TVCBalance: number;
  setTVCBalance: Function;
  getTVCBalance: Function;
  MNGBalance: number; //manager balance 0-2000

  setMNGBalance: Function;
  getMNGBalance: Function;
  ETHBalance: number; //eth internal balance
  setETHBalance: Function;
};

const initialState: TokenTVCProps = {
  TVCBalance: 0,

  setTVCBalance: Function,
  getTVCBalance: Function,
  MNGBalance: 0,
  setMNGBalance: Function,
  getMNGBalance: Function,
  ETHBalance: 0,
  setETHBalance: Function
};

const TokenTVCContext = createContext(initialState);

type TokenTVCProviderProps = {
  children: ReactNode;
};

function TokenTVCProvider({ children }: TokenTVCProviderProps) {
  const { user, isAuthenticated } = useMoralis();
  const { data, error, fetch } = useMoralisCloudFunction('getManagerBalance', undefined, {
    autoFetch: false
  });
  const {
    data: dataMNG,
    error: errorMNG,
    fetch: fetchMNG
  } = useMoralisCloudFunction('getManagerProgress', undefined, {
    autoFetch: false
  });
  const [TVCBalance, setTVCBalance] = useState(0);
  const [MNGBalance, setMNGBalance] = useState(0);
  const [ETHBalance, setETHBalance] = useState(0);

  const getTVCBalance = async () => {
    if (user && isAuthenticated) {
      await fetch();
    }
  };

  const getMNGBalance = async () => {
    if (user && isAuthenticated) {
      await fetchMNG();
    }
  };

  useEffect(() => {
    getTVCBalance();
    getMNGBalance();
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (data) {
      const res = data as { balance: number; balanceETH: number };
      setTVCBalance(res.balance);
      setETHBalance(res.balanceETH);
    }
  }, [data]);

  useEffect(() => {
    if (dataMNG) {
      const res = dataMNG as { managerActualTotalPoints: number };
      setMNGBalance(res.managerActualTotalPoints);
    }
  }, [dataMNG]);

  useEffect(() => {
    if (error) console.log('errorTokenTVCContext', error);
  }, [error]);

  useEffect(() => {
    if (errorMNG) console.log('errorMNGContext', errorMNG);
  }, [errorMNG]);

  return (
    <TokenTVCContext.Provider
      value={{
        TVCBalance,
        setTVCBalance,
        getTVCBalance,
        MNGBalance,
        setMNGBalance,
        getMNGBalance,
        ETHBalance,
        setETHBalance
      }}
    >
      {children}
    </TokenTVCContext.Provider>
  );
}

export { TokenTVCProvider, TokenTVCContext };
