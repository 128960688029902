export const avatars = [
  { src: '/static/avatars/AVATAR-01AVATARS.svg', id: 1 },
  { src: '/static/avatars/AVATAR-02AVATARS.svg', id: 2 },
  { src: '/static/avatars/AVATAR-03AVATARS.svg', id: 3 },
  { src: '/static/avatars/AVATAR-04AVATARS.svg', id: 4 },
  { src: '/static/avatars/AVATAR-05AVATARS.svg', id: 5 },
  { src: '/static/avatars/AVATAR-06AVATARS.svg', id: 6 },
  { src: '/static/avatars/AVATAR-07AVATARS.svg', id: 7 },
  { src: '/static/avatars/AVATAR-08AVATARS.svg', id: 8 },
  { src: '/static/avatars/AVATAR-09AVATARS.svg', id: 9 },
  { src: '/static/avatars/AVATAR-10AVATARS.svg', id: 10 },
  { src: '/static/avatars/AVATAR-11AVATARS.svg', id: 11 },
  { src: '/static/avatars/AVATAR-12AVATARS.svg', id: 12 },
  { src: '/static/avatars/AVATAR-13AVATARS.svg', id: 13 },
  { src: '/static/avatars/AVATAR-14AVATARS.svg', id: 14 },
  { src: '/static/avatars/AVATAR-15AVATARS.svg', id: 15 },
  { src: '/static/avatars/AVATAR-16AVATARS.svg', id: 16 },
  { src: '/static/avatars/AVATAR-17AVATARS.svg', id: 17 },
  { src: '/static/avatars/AVATAR-18AVATARS.svg', id: 18 },
  { src: '/static/avatars/AVATAR-19AVATARS.svg', id: 19 },
  { src: '/static/avatars/AVATAR-20AVATARS.svg', id: 20 },
  { src: '/static/avatars/AVATAR-21AVATARS.svg', id: 21 }
];

export const credentials = [
  { src: '/static/credentials/front/FRENTE-01.svg' },
  { src: '/static/credentials/front/FRENTE-02.svg' },
  { src: '/static/credentials/front/FRENTE-03.svg' },
  { src: '/static/credentials/front/FRENTE-04.svg' },
  { src: '/static/credentials/front/FRENTE-05.svg' },
  { src: '/static/credentials/front/FRENTE-06.svg' },
  { src: '/static/credentials/front/FRENTE-07.svg' },
  { src: '/static/credentials/front/FRENTE-08.svg' },
  { src: '/static/credentials/front/FRENTE-09.svg' },
  { src: '/static/credentials/front/FRENTE-10.svg' },
  { src: '/static/credentials/front/FRENTE-11.svg' },
  { src: '/static/credentials/front/FRENTE-12.svg' },
  { src: '/static/credentials/front/FRENTE-13.svg' },
  { src: '/static/credentials/front/FRENTE-14.svg' },
  { src: '/static/credentials/front/FRENTE-15.svg' },
  { src: '/static/credentials/front/FRENTE-16.svg' },
  { src: '/static/credentials/front/FRENTE-17.svg' },
  { src: '/static/credentials/front/FRENTE-18.svg' },
  { src: '/static/credentials/front/FRENTE-19.svg' },
  { src: '/static/credentials/front/FRENTE-20.svg' },
  { src: '/static/credentials/front/FRENTE-21.svg' }
];
