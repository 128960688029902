import { useState, useEffect } from 'react';
import { useChain, useMoralis } from 'react-moralis';
import { DataBox } from 'types/nftTypes';
import useMysteryBoxDetails from './useMysteryBoxDetails';

export default function useAllMysteryBoxDetails() {
  const { chainId } = useChain();
  const { user, isWeb3Enabled } = useMoralis();

  const {
    box: boxBasic,
    error: errorBasic,
    getMysteryBox: fetchBasic,
    isFetching: isFetchingBasic,
    isLoading: isLoadingBasic
  } = useMysteryBoxDetails(1);

  const {
    box: boxCommon,
    error: errorCommon,
    getMysteryBox: fetchCommon,
    isFetching: isFetchingCommon,
    isLoading: isLoadingCommon
  } = useMysteryBoxDetails(2);

  const {
    box: boxPremium,
    error: errorPremium,
    getMysteryBox: fetchPremium,
    isFetching: isFetchingPremium,
    isLoading: isLoadingPremium
  } = useMysteryBoxDetails(3);

  const [basic, setBasic] = useState<DataBox | undefined>();
  const [common, setCommon] = useState<DataBox | undefined>();
  const [premium, setPremium] = useState<DataBox | undefined>();

  const loadingBoxes = isLoadingBasic || isLoadingCommon || isLoadingPremium;
  const deployChainId = process.env.REACT_APP_CHAIN_ID;

  const getAllBoxes = async () => {
    if (isWeb3Enabled) {
      await fetchBasic();
      await fetchCommon();
      await fetchPremium();
    }
  };

  useEffect(() => {
    if (deployChainId && chainId === deployChainId && isWeb3Enabled && !loadingBoxes) {
      getAllBoxes();
    } /* else {
      setBasic(undefined);
      setCommon(undefined);
      setPremium(undefined);
    } */
  }, [chainId, user, isWeb3Enabled]);

  useEffect(() => {
    if (boxBasic) setBasic(boxBasic);
    if (boxCommon) setCommon(boxCommon);
    if (boxPremium) setPremium(boxPremium);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxBasic, boxCommon, boxPremium]);

  return {
    getAllBoxes,
    basic,
    common,
    premium,
    error: errorBasic || errorCommon || errorPremium,
    isFetching: isFetchingBasic || isFetchingCommon || isFetchingPremium,
    isLoading: isLoadingBasic || isLoadingCommon || isLoadingPremium
  };
}
