export type YoutubeData = {
  playlist: string;
  currentSong: number;
  autoplay: boolean;
  volume: number;
};

export const getYoutubeUserData = () => {
  let data = localStorage.getItem('youtube');
  if (data) {
    const youtubeData = JSON.parse(data);
    return youtubeData as YoutubeData;
  }
};

export const saveYoutubeUserData = (youtubeData: YoutubeData) => {
  localStorage.setItem('youtube', JSON.stringify(youtubeData));
};
