import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import esLocales from './translations/es.json';
import enLocales from './translations/en.json';

import esLocalesTours from './translations/esTours.json';
import enLocalesTours from './translations/enTours.json';

import esLocalesStaking from './translations/esStaking.json';
import enLocalesStaking from './translations/enStaking.json';

import enLocalesRentals from './translations/enRentals.json';
import esLocalesRentals from './translations/esRentals.json';

import enLocalesRankings from './translations/enRankings.json';
import esLocalesRankings from './translations/esRankings.json';

import enLocalesTickets from './translations/enTickets.json';
import esLocalesTickets from './translations/esTickets.json';

// ----------------------------------------------------------------------
const enTotalLocales = {
  ...enLocales,
  ...enLocalesTours,
  ...enLocalesStaking,
  ...enLocalesRentals,
  ...enLocalesRankings,
  ...enLocalesTickets
};
const esTotalLocales = {
  ...esLocales,
  ...esLocalesTours,
  ...esLocalesStaking,
  ...esLocalesRentals,
  ...esLocalesRankings,
  ...esLocalesTickets
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enTotalLocales },
      es: { translations: esTotalLocales }
    },
    lng: localStorage.getItem('i18nextLng') || 'es',
    fallbackLng: 'es',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
