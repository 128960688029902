import { ModalsContext } from 'contexts/ModalsContext';
import { useContext, useEffect } from 'react';

export default function useModalV2(defaultModal?: JSX.Element) {
  const { handleOpen, handleClose, handleIsClosable, setModalBody, previousModalBody } =
    useContext(ModalsContext);

  useEffect(() => {
    if (defaultModal) {
      setModalBody(defaultModal);
    }
  }, [defaultModal]);

  return {
    handleOpen,
    handleClose,
    handleIsClosable,
    setModalBody,
    previousModalBody
  };
}
