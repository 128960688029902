import { Button, Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/lotties/error.json';
import useWordings from '../../locales/wordings/useWordings';

interface CongratsProps {
  handleClose: Function;
}

const TransactionErrorBody = (props: CongratsProps) => {
  const { handleClose } = props;
  const { purchaseErrorModalWordings } = useWordings();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Lottie
        style={{ width: '95px', height: '95px' }}
        loop={false}
        animationData={errorAnimation}
      />
      <Typography variant="h6" textAlign="center" gutterBottom>
        {purchaseErrorModalWordings.title}
      </Typography>
      <Typography variant="body2" textAlign="center" paragraph>
        {purchaseErrorModalWordings.descLine1}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleClose()}>
        {purchaseErrorModalWordings.buttonText}
      </Button>
    </Container>
  );
};

export default TransactionErrorBody;
