import { useEffect, useState } from 'react';
import { Grid, Tooltip, tooltipClasses, TooltipProps, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
import { Icon } from '@iconify/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// hooks
import { useMoralisCloudFunction } from 'react-moralis';
import useModalV2 from 'hooks/useModalV2';
import useRentalsWordings from 'locales/wordings/useRentalsWordings';
import useHandlerErrors from 'hooks/useHandlerErrors';
import useTranslations from 'hooks/useTranslations';
// types & component
import ShareReferredCodeBody from 'components/modals/rentals/ShareReferredCodeBody';
import { GeneratePromoCodeResponse, GetPromoCodeResponse } from 'types/rentalsTypes';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    padding: 10
  }
});

const ReferredCode = () => {
  const { errorLaunch, launch400 } = useHandlerErrors();
  const { value: lang } = useTranslations().currentLang;
  const { referredCodeWordings } = useRentalsWordings();
  const { setModalBody, handleOpen } = useModalV2();
  const [promoCodeData, setPromoCodeData] = useState<GetPromoCodeResponse>();
  const { fetch, isLoading, isFetching } = useMoralisCloudFunction(
    'generatePromoCodeRentNft',
    undefined,
    { autoFetch: false }
  );
  const { data } = useMoralisCloudFunction('getPromoCode');

  useEffect(() => {
    if (data && !errorLaunch(data)) {
      const res = data as GetPromoCodeResponse;
      setPromoCodeData({ ...res, discount: res.discount * 100 });
    }
  }, [data]);

  const handleGenerateCode = async () => {
    await fetch({
      onSuccess: (data) => {
        if (data && !errorLaunch(data)) {
          const res = data as GeneratePromoCodeResponse;
          if (promoCodeData) {
            setPromoCodeData({
              ...promoCodeData,
              discount: res.discount * 100,
              promoCode: res.promoCode
            });
          }
        }
      },
      onError: () => launch400()
    });
  };

  const handleShareCode = () => {
    setModalBody(
      <ShareReferredCodeBody
        promoCode={promoCodeData!.promoCode}
        discount={promoCodeData!.discount}
      />
    );
    handleOpen();
  };

  if (!data || !promoCodeData?.available) {
    return null;
  }

  return (
    <Grid container alignItems="center" justifyContent="center" height={100} pt={5} pb={25} gap={2}>
      <Grid container alignItems="center" justifyContent="center" gap={2}>
        <Typography variant="h5">{referredCodeWordings.youReferredCode}</Typography>
        {promoCodeData?.infoPromoCode[lang] && (
          <CustomWidthTooltip title={promoCodeData?.infoPromoCode[lang]} placement="top-end" arrow>
            <Icon icon="eva:info-fill" width={30} color="rgba(24, 144, 255, 0.48)" />
          </CustomWidthTooltip>
        )}
      </Grid>
      {promoCodeData?.promoCode && (
        <Grid container alignItems="center" justifyContent="center" gap={2}>
          <Typography variant="h4">{promoCodeData?.promoCode}</Typography>
          <CustomWidthTooltip title={referredCodeWordings.copy} placement="right" arrow>
            <IconButton onClick={() => navigator.clipboard.writeText(promoCodeData?.promoCode)}>
              <ContentCopyIcon />
            </IconButton>
          </CustomWidthTooltip>
        </Grid>
      )}
      <LoadingButton
        variant="contained"
        loading={isLoading || isFetching}
        size="small"
        onClick={promoCodeData?.promoCode ? handleShareCode : handleGenerateCode}
      >
        {promoCodeData?.promoCode
          ? referredCodeWordings.shareCode
          : referredCodeWordings.generateCode}
      </LoadingButton>
    </Grid>
  );
};

export default ReferredCode;
