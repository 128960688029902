import { Container, Typography } from '@mui/material';
import useWordings from 'locales/wordings/useWordings';
import Lottie from 'lottie-react';
import disconnectAnimation from '../../assets/lotties/disconnect.json';

const ServerErrorBody = () => {
  const { serverErrorModalWordings } = useWordings();
  const description = [
    serverErrorModalWordings.descLine1,
    serverErrorModalWordings.descLine2,
    serverErrorModalWordings.descLine3
  ];

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Lottie style={{ width: '95px', height: '95px' }} animationData={disconnectAnimation} />
      <Typography variant="h6" textAlign="center" gutterBottom>
        {serverErrorModalWordings.title}
      </Typography>
      <Typography variant="body2" textAlign="center" paragraph>
        {description.map((line, i) => (
          <div key={i}>
            {line}
            <br />
          </div>
        ))}
      </Typography>
    </Container>
  );
};

export default ServerErrorBody;
