import { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
// material
import { Box, Container, Typography, Divider, Grid } from '@mui/material';
// components
import Page from '../components/Page';
import MaintenancePage from '../pages/statusPages/MaintenancePage';
import InvalidChain from '../pages/statusPages/InvalidChain';
import ConnectWalletBody from '../components/modals/ConnectWalletBody';
import ServerErrorBody from '../components/modals/ServerErrorBody';
import LoadingScreen from 'components/LoadingScreen';
// hooks
import useModal from '../hooks/useModal';
import useCheckChain from '../hooks/useCheckChain';

type PageLayoutProps = {
  title: string;
  subtitle?: string;
  serverError?: boolean;
  maintenance: boolean;
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[];
  topChildren?: JSX.Element; //upper to title
  rightChildren?: JSX.Element; //display on top right
  bottomChildren?: JSX.Element; //lower to line/divider
};

export default function PageLayout(props: PageLayoutProps) {
  const { title, subtitle, serverError, maintenance, isLoading, children } = props;
  const { invalidChain } = useCheckChain();
  const { user } = useMoralis();
  const { BaseModal, open, handleOpen, handleClose: closeModal } = useModal();
  const [selectedBody, setSelectedBody] = useState(<ServerErrorBody />);

  useEffect(() => {
    if (serverError) {
      handleOpen();
      setSelectedBody(<ServerErrorBody />);
    } else if (user) {
      closeModal();
    } else {
      handleOpen();
      setSelectedBody(<ConnectWalletBody />);
    }

    if (!user) {
      handleOpen();
      setSelectedBody(<ConnectWalletBody />);
    } else if (!serverError) {
      closeModal();
    } else {
      handleOpen();
      setSelectedBody(<ServerErrorBody />);
    }
  }, [serverError, user]);

  const handleClose = () => {
    closeModal();
    return true;
  };

  return (
    <Page title={title}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          {props.topChildren && <Grid sx={{ pb: 5 }}>{props.topChildren}</Grid>}
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container flexDirection="column">
                <Grid item>
                  <Typography variant="h4">{title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" color="text.secondary">
                    {subtitle}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>{props.rightChildren}</Grid>
          </Grid>
          <Divider sx={{ pt: 2 }} />
          {props.bottomChildren}
        </Box>
        {maintenance ? (
          <MaintenancePage />
        ) : invalidChain ? (
          <InvalidChain />
        ) : isLoading ? (
          <Grid container justifyContent="center" alignItems="center" height={'70vh'}>
            <LoadingScreen />
          </Grid>
        ) : (
          children
        )}
      </Container>
      <BaseModal open={open} handleClose={() => handleClose()}>
        {selectedBody}
      </BaseModal>
    </Page>
  );
}
