import useTranslations from '../../hooks/useTranslations';

const useRentalsWordings = () => {
  const { translate } = useTranslations();

  const proposalWordings = {
    title: translate('rentals.proposal.title'),
    description: translate('rentals.proposal.description'),
    limitedTime: translate('rentals.proposal.limitedTime'),
    moreInfo: translate('rentals.proposal.moreInfo'),
    moreInfoUrl: translate('rentals.proposal.moreInfoUrl'),
    question: translate('rentals.proposal.question'),
    reject: translate('rentals.proposal.reject'),
    accept: translate('rentals.proposal.accept'),
    youHaveSelected: translate('rentals.proposal.youHaveSelected'),
    confirmRejectQuestion: translate('rentals.proposal.confirmRejectQuestion'),
    confirmAcceptQuestion: translate('rentals.proposal.confirmAcceptQuestion'),
    cancel: translate('rentals.proposal.cancel'),
    iAmSure: translate('rentals.proposal.iAmSure'),
    userWithoutMusician: translate('rentals.proposal.userWithoutMusician')
  };

  const cardWordings = {
    title: translate('rentals.card.title'),
    description: translate('rentals.card.description'),
    limitedTime: translate('rentals.card.limitedTime'),
    value: translate('rentals.card.value'),
    availableRental: translate('rentals.card.availableRental'),
    musiciansEvery: translate('rentals.card.musiciansEvery'),
    new: translate('rentals.card.new'),
    rentMusician: translate('rentals.card.rentMusician'),
    days: translate('rentals.card.days')
  };

  const checkoutWordings = {
    title: translate('rentals.checkoutModal.title'),
    descLine1: translate('rentals.checkoutModal.descLine1'),
    discountCodes: translate('rentals.checkoutModal.discountCodes'),
    apply: translate('rentals.checkoutModal.apply'),
    subTotal: translate('rentals.checkoutModal.subTotal'),
    total: translate('rentals.checkoutModal.total'),
    discount: translate('rentals.checkoutModal.discount'),
    buttonText: translate('rentals.checkoutModal.buttonText'),
    cardDesc1: translate('rentals.checkoutModal.cardDesc1'),
    cardDesc2: translate('rentals.checkoutModal.cardDesc2'),
    searchingMusician: translate('rentals.checkoutModal.searchingMusician')
  };

  const referredCodeWordings = {
    youReferredCode: translate('rentals.referredCode.youReferredCode'),
    generateCode: translate('rentals.referredCode.generateCode'),
    copy: translate('rentals.referredCode.copy'),
    shareCode: translate('rentals.referredCode.shareCode'),
    shareCodeMessage: translate('rentals.referredCode.shareCodeMessage')
  };

  const characterModalWordings = {
    title: translate('rentals.characterModal.title'),
    description: translate('rentals.characterModal.description'),
    button: translate('rentals.characterModal.button')
  };

  const wordings = {
    proposalWordings,
    cardWordings,
    checkoutWordings,
    characterModalWordings,
    referredCodeWordings
  };

  return wordings;
};

export default useRentalsWordings;
