import PageLayout from 'layouts/PageLayout';
import useWordings from 'locales/wordings/useWordings';
import CreateManagerForm from '../components/CreateManagerForm';

// Create and Edit manager
export default function CreateManager() {
  const { menuWordings } = useWordings();

  return (
    <PageLayout title={menuWordings.myManager} maintenance={false}>
      <CreateManagerForm />
    </PageLayout>
  );
}
