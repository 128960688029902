import { useContext } from 'react';
import useLaunchSnackbar from './useLaunchSnackbar';
import { ErrorModalLauncherContext } from 'contexts/ErrorModalLauncherContext';
import { ErrorResponse } from 'types/errorsTypes';
import GenericErrorBody from 'components/modals/GenericErrorBody';
import useTranslations from './useTranslations';
import useWordings from 'locales/wordings/useWordings';

export type SnackbarStatus = 'error' | 'success' | 'default' | 'info' | 'warning';

export default function useHandlerErrors() {
  const { value: lang } = useTranslations().currentLang;
  const { snackbarsWordings } = useWordings();
  const { launchSnackbar } = useLaunchSnackbar();
  const { launchModal, handleClose } = useContext(ErrorModalLauncherContext);

  const automaticLaunch = (error: ErrorResponse) => {
    const { type, title, message, status, action } = error;

    if (type === 'MODAL') {
      launchModal(
        <GenericErrorBody
          title={title![lang] || 'Error'}
          descriptions={message[lang]}
          buttonText={action?.text[lang]}
          url={action?.url}
          action={handleClose}
        />,
        true,
        true
      );
    } else {
      launchSnackbar(message[lang][0], status);
    }
  };

  const errorLaunch = (data: any | null) => {
    if (data === null) return false;

    if (data.isError) {
      automaticLaunch(data as ErrorResponse);
      return true;
    }
    return false;
  };

  const launch400 = () => {
    launchSnackbar(snackbarsWordings.serverError, 'error');
  };

  return {
    launchSnackbar,
    launchModal,
    automaticLaunch,
    errorLaunch,
    launch400
  };
}
