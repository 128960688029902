import { Avatar, Typography, Grid, Tooltip } from '@mui/material';
import React from 'react';

interface TokenDetailProps {
  image: string;
  type?: string;
  value?: string;
  style?: Object;
  isScientificNotation?: boolean;
}

const TokenDetail = (props: TokenDetailProps) => {
  const { image, value = '0.000000', style, isScientificNotation } = props;
  /* const cutValue = (withDecimals = '0.000000') => {
    const [number, decimals] = withDecimals.split('.');
    return `${number}.${decimals?.substring(0, 2) || '00'}`;
  }; */

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      sx={{
        mr: 2,
        border: '1.5px solid #1890FF',
        borderRadius: '15px',
        padding: '2px 5px 2px 5px',
        ...style
      }}
    >
      {isScientificNotation ? (
        <Tooltip title={parseFloat(value).toFixed(15) + ' ETH'} placeholder="bottom-end" arrow>
          <Grid container alignItems="center">
            <Avatar alt="Token Detail" src={image} sx={{ width: 28, height: 28, mr: 1 }} />
            <Typography variant="body2">{parseFloat(value).toFixed(2)}</Typography>
          </Grid>
        </Tooltip>
      ) : (
        <>
          <Avatar alt="Token Detail" src={image} sx={{ width: 28, height: 28, mr: 1 }} />
          <Typography variant="body2">{parseFloat(value).toFixed(2)}</Typography>
        </>
      )}
    </Grid>
  );
};

export default TokenDetail;
