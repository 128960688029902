import { Icon } from '@iconify/react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useRentalsWordings from 'locales/wordings/useRentalsWordings';
import { buildWording } from 'utils/wordingReplace';

type Props = {
  promoCode: string;
  discount: number;
};

const ShareReferredCodeBody = ({ promoCode, discount }: Props) => {
  const { referredCodeWordings } = useRentalsWordings();
  const shopUrl = encodeURI(`${window.origin}/shop`);
  const message = encodeURI(
    buildWording(referredCodeWordings.shareCodeMessage, [promoCode, discount])
  );

  return (
    <Grid container justifyContent="center" alignItems="center" gap={1}>
      <Typography variant="h5" gutterBottom>
        {referredCodeWordings.shareCode}
      </Typography>
      <Grid container alignItems="center" justifyContent="center" gap={2}>
        <Typography variant="h4">{promoCode}</Typography>
        <Tooltip title={referredCodeWordings.copy} placement="right" arrow>
          <IconButton onClick={() => navigator.clipboard.writeText(promoCode)}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ pt: 1 }} gap={1}>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href={`https://twitter.com/intent/tweet?text=${message}&url=${shopUrl}&hashtags=NFT%2CBlockchain%2CTheVoiceCrypto`}
            target={'_blank'}
            rel="noreferrer"
          >
            <Icon icon="logos:twitter" width={30} height={26} />
          </a>
          Twitter
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href={`https://t.me/share/url?url=${shopUrl}&text=${message}`}
            target={'_blank'}
            rel="noreferrer"
          >
            <Icon icon="logos:telegram" width={30} height={26} />
          </a>
          Telegram
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shopUrl}&quote=${message}`}
            target={'_blank'}
            rel="noreferrer"
          >
            <Icon icon="logos:facebook" width={30} height={26} />
          </a>
          Facebook
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href={`https://api.whatsapp.com/send?text=${message}%20${shopUrl}`}
            data-action="share/whatsapp/share"
            target={'_blank'}
            rel="noreferrer"
          >
            <Icon icon="logos:whatsapp" width={30} height={26} />
          </a>
          Whatsapp
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareReferredCodeBody;
