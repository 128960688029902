import { useState } from 'react';
import { formatEther } from '@ethersproject/units';
import { useERC20Balances } from 'react-moralis';
import { Moralis } from 'moralis';
import Web3 from 'web3';

interface ERC20Data {
  token_address: string;
  name: string;
  symbol: string;
  logo: string;
  thumbnail: string;
  decimals: string;
  balance: string;
}

const providerURL = process.env.REACT_APP_PROVIDER_URL || 'http://localhost:7545';
const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(providerURL));
export default function useUser() {
  const [balance, setBalance] = useState();
  const [ethBalance, setEthBalance] = useState<number>();
  const [tvcBalance, setTvcBalance] = useState<number>();
  const { data: ercBalances, fetchERC20Balances } = useERC20Balances();

  const getBalance = async (address: string) => {
    await web3.eth.getBalance(address, function (err: Error, result: any) {
      if (err) {
        console.log(err);
      } else {
        console.log('result: ', result);
        setBalance(result && formatEther(result));
      }
    });
  };

  const getTokenBalances = async (address: string) => {
    let chain = process.env.REACT_APP_CHAIN_ID as any;
    let erc20 = await Moralis.Web3API.account.getTokenBalances({
      chain: chain, //cambiar testnet a bsc
      address: address
    });

    if (erc20) {
      erc20?.map((el) => {
        console.log('el', el);
        if (
          process.env.REACT_APP_ADDRESS_TOKEN &&
          el.token_address === process.env.REACT_APP_ADDRESS_TOKEN.toLowerCase()
        ) {
          setTvcBalance(Number(Moralis.Units.FromWei(el.balance)));
        }
        if (el.symbol.toLocaleLowerCase() === 'eth') {
          setEthBalance(Number(Moralis.Units.FromWei(el.balance)));
        }
      });
    }
  };

  const listenToUpdate = async (address: string) => {
    let query = new Moralis.Query('EthTransactions');
    let subscription = await query.subscribe();

    subscription.on('create', (object) => {
      getBalance(address);
    });
  };
  return {
    balance,
    getTokenBalances,
    ethBalance,
    tvcBalance,
    listenToUpdate,
    getBalance
  };
}
