import { ReactNode, createContext, useState } from 'react';
import GenericErrorBody from 'components/modals/GenericErrorBody';
import useModal from 'hooks/useModal';

export type ErrorModalLauncherProps = {
  open: boolean;
  isClosable: boolean;
  modalBodySelected: JSX.Element;
  handleOpen: Function;
  handleClose: Function;
  launchModal: (modalBody: JSX.Element, open?: boolean, closable?: boolean) => void;
};

const initialState: ErrorModalLauncherProps = {
  open: false,
  isClosable: false,
  modalBodySelected: <></>,
  handleOpen: () => {},
  handleClose: () => {},
  launchModal: (modalBody: JSX.Element, open?: boolean, closable?: boolean) => {}
};

const ErrorModalLauncherContext = createContext(initialState);

type ErrorModalLauncherProviderProps = {
  children: ReactNode;
};

function ErrorModalLauncherProvider({ children }: ErrorModalLauncherProviderProps) {
  const { open, handleClose, handleOpen } = useModal();
  const [isClosable, setIsClosable] = useState(false);
  const [modalBodySelected, setModalBodySelected] = useState<JSX.Element>(
    <GenericErrorBody title={'Error'} descriptions={[]} />
  );

  const launchModal = (modalBody: JSX.Element, open = true, closable?: boolean): void => {
    setModalBodySelected(modalBody);
    setIsClosable(closable || false);
    if (open) {
      handleOpen();
    }
  };

  return (
    <ErrorModalLauncherContext.Provider
      value={{
        open,
        isClosable,
        modalBodySelected,
        handleClose,
        handleOpen,
        launchModal
      }}
    >
      {children}
    </ErrorModalLauncherContext.Provider>
  );
}

export { ErrorModalLauncherProvider, ErrorModalLauncherContext };
